import { useMediaQuery, useTheme } from "@mui/material";
import DistrictTypography from "../../../district/theme/DistrictTypography";
import { l_holding } from "../../locales";
import HMTypography from "../../theme/HmTypography";

const IdentifyProblem = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <div className="mx-auto my-10 max-w-[600px] px-6 py-5 md:mt-8 md:max-w-[1100px] md:px-10 lg:px-12">
      <HMTypography
        variant={"body1"}
        className="mb-6 max-w-[180px] text-black/[0.2] md:mb-12 md:max-w-[250px]"
      >
        {l_holding("identifyingProblem.title")}
      </HMTypography>
      <HMTypography
        variant={isOverMd ? "body1" : "body2"}
        className="mb-8 md:mb-12"
      >
        {l_holding("identifyingProblem.text1")}
      </HMTypography>
      <HMTypography
        variant={isOverMd ? "body1" : "body2"}
        className="mb-12 md:mb-12"
      >
        {l_holding("identifyingProblem.text2")}
      </HMTypography>

      <HMTypography
        variant={"body1"}
        className="bg-clip-text"
        style={{
          backgroundImage:
            "linear-gradient(100deg, #FF0000,#FF06E6,#0B06FF,#FFAA06)",
        }}
      >
        {l_holding("identifyingProblem.text3_1")}
        <HMTypography
          variant="span"
          className="bg-inherit bg-clip-text text-transparent"
        >
          {l_holding("identifyingProblem.text3_2")}
        </HMTypography>
        {l_holding("identifyingProblem.text3_3")}
      </HMTypography>
    </div>

    // </div>
  );
};

export default IdentifyProblem;
