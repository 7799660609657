// // @ts-ignore
// import face from "./images/face.svg";
import { ButtonBase, useMediaQuery, useTheme } from "@mui/material";
import gsap, { Linear } from "gsap";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { animated, easings, useSpring } from "react-spring";
import useResizeObserver from "use-resize-observer";
import { cn } from "../../../../../utils/cn";
import { l_holding } from "../../locales";
import HMTypography from "../../theme/HmTypography";
import { CloseIcon } from "./CloseIcon";
import { Cards } from "./HowToOverCome";
// @ts-ignore
import whiteMaskFace from "./images/white_mask_with_face.svg";
// @ts-ignore
import words from "./images/words.png";
// @ts-ignore
import modalImg from "./images/modal_img.png";
// @ts-ignore
import mobileImage from "./images/image.png";
import ModalBase from "./ModalBase";

const WhiteCard = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: Cards;
  setIsOpen: Dispatch<SetStateAction<Cards>>;
}) => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));
  const isOverLg = useMediaQuery(theme.breakpoints.up("lg"));

  const { ref, width } = useResizeObserver();

  const [shouldBeOnTop, setShouldBeOnTop] = useState(false);

  useEffect(() => {
    gsap.fromTo(
      "#words-on-mask",
      { xPercent: 0 },
      {
        xPercent: -100,
        duration: 10,
        ease: Linear.easeNone,
        repeat: -1,
      },
    );
  }, [isOverMd]);

  const containerAnimation = useSpring({
    scale: isOpen === "second" ? 0.7 : 1,
    left: isOpen === "second" ? "20%" : "0%",
    config: { duration: 400 },
  });

  const cardAnimation = useSpring({
    width: width,
    config: { duration: 500, easing: easings.easeInOutSine },
    onRest: () => {
      if (isOpen !== "first") {
        setShouldBeOnTop(false);
      }
    },
  });

  const rightDiv = useSpring({
    value: isOpen === "first" ? "40%" : "0%",
    config: { duration: 500 },
  });

  const titleAnimation = useSpring({
    height: isOpen === "first" ? "0px" : isOverLg ? "240px" : "200px",
    config: { duration: 500 },
  });

  return (
    <>
      <animated.div
        style={isOverMd ? containerAnimation : {}}
        className={cn(
          "left-0 top-0 aspect-[353/450] h-full w-[calc(50%-10px)] md:absolute md:aspect-auto",
          shouldBeOnTop && "z-[2]",
          (isOpen === "first" || !isOverMd) && "w-[100%]",
        )}
        ref={ref}
      >
        <animated.div
          className={cn(
            "relative h-full w-full overflow-hidden rounded-[40px] bg-white shadow-[rgba(199,199,206,0.2)_0px_0px_30px_0px]",
          )}
          style={isOverMd ? cardAnimation : {}}
        >
          <div className="flex h-full flex-row">
            <animated.div className={cn("flex h-full flex-1 flex-col")}>
              {isOverMd && (
                <animated.div
                  className={cn(
                    "transition-opacity",
                    isOpen === "first"
                      ? "opacity-0 duration-75"
                      : "opacity-1 delay-[500ms] duration-300",
                  )}
                  style={titleAnimation}
                >
                  <HMTypography
                    variant="title"
                    className="box-border max-w-[460px] px-10 pt-10 lg:px-12 lg:pt-12"
                  >
                    {l_holding("overcome.whiteCard_title")}
                  </HMTypography>
                </animated.div>
              )}
              {isOverMd ? (
                <div
                  className={cn("mt-10 h-full w-full")}
                  style={{
                    boxSizing: "border-box",
                    maskImage: `url(${whiteMaskFace})`,
                    WebkitMaskImage: `url(${whiteMaskFace})`,
                    maskSize: "120% auto",
                    WebkitMaskSize: "120% auto",
                    maskRepeat: "no-repeat",
                    WebkitMaskRepeat: "no-repeat",
                    maskPosition: isOverMd ? "top left" : "bottom left",
                    WebkitMaskPosition: isOverMd ? "top left" : "bottom left",
                    maskMode: "luminance",
                    backgroundImage: "linear-gradient(#000, #000)",
                    backgroundSize: "contain",
                  }}
                >
                  {/* <div className="absolute -top-[1px] left-0 z-[2] h-full w-full">
              <img src={face} className="w-full" />
              </div> */}
                  <div className="absolute flex h-full w-full flex-row">
                    <div id={"words-on-mask"}>
                      <img src={words} className="h-[120%] md:h-full" />
                    </div>
                    <div id={"words-on-mask"}>
                      <img src={words} className="h-[120%] md:h-full" />
                    </div>
                    <div id={"words-on-mask"}>
                      <img src={words} className="h-[120%] md:h-full" />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="h-full w-full">
                  <img
                    src={mobileImage}
                    className="h-full w-full object-cover"
                  />
                </div>
              )}
            </animated.div>
            {isOverMd && (
              <animated.div
                className={"relative box-border h-full"}
                style={{
                  width: rightDiv.value,
                }}
              >
                <div
                  className={cn(
                    "absolute right-0 top-0 box-border flex h-full max-h-[min(80%,450px)] w-[100%] flex-col justify-between transition-opacity",
                    isOpen === "first"
                      ? "px-8 py-10 opacity-100 delay-[500ms] duration-300 lg:px-12 lg:pt-12"
                      : "opacity-0 delay-0 duration-0",
                  )}
                >
                  <HMTypography
                    variant="title"
                    className="mb-4 max-w-[460px] text-[#D9D9D9] md:text-[44px] md:leading-[46px] lg:text-[60px] lg:leading-[64px]"
                  >
                    {l_holding("overcome.whiteCard_title")}
                  </HMTypography>
                  <HMTypography variant="body2" className="font-bold">
                    {l_holding("overcome.whiteCard_text")}
                  </HMTypography>
                </div>
              </animated.div>
            )}
          </div>
          <div className="absolute bottom-8 right-8 lg:bottom-12 lg:right-12">
            <ButtonBase
              onClick={() => {
                setShouldBeOnTop(true);
                setIsOpen((p) => (p === "first" ? "none" : "first"));
              }}
              className={cn(
                "flex items-center justify-center rounded-full bg-[#D9D9D9] p-1 duration-500 hover:bg-[#cfcfcf]",
                isOpen === "first" && isOverMd ? "rotate-0" : "-rotate-45",
              )}
            >
              <CloseIcon color="white" size={30} />
            </ButtonBase>
          </div>
        </animated.div>
      </animated.div>
      <ModalBase
        isOpen={isOpen === "first" && !isOverMd}
        setIsOpen={() => setIsOpen("none")}
      >
        <div>
          <HMTypography variant="title" className="mb-6">
            {l_holding("overcome.whiteCard_title")}
          </HMTypography>
          <div className="max-h-[70vh] overflow-y-auto">
            <HMTypography variant="body2" className="mb-6">
              {l_holding("overcome.whiteCard_text")}
            </HMTypography>
            <div className="mx-auto w-full">
              <img className="w-full" src={modalImg} />
            </div>
          </div>
        </div>
      </ModalBase>
    </>
  );
};

export default WhiteCard;
