import { HeadProps } from "gatsby";
import SEOHead, { initHeadLanguage } from "../../components/old/seo_head";
import HoldingModa from "../../features/case_study/holding_moda";
import { l_holding } from "../../features/case_study/holding_moda/locales";

export default HoldingModa;

export const Head = (headProps: HeadProps) => {
  initHeadLanguage(headProps);

  return (
    <SEOHead
      title={l_holding("seo.title")}
      description={l_holding("seo.description")}
      image="/case_studies_png/cover-hmoda.png"
      headProps={headProps}
    />
  );
};
