import { useState } from "react";
import { cn } from "../../../../../utils/cn";
import { l_holding } from "../../locales";
import HMTypography from "../../theme/HmTypography";
import BlackCard from "./BlackCard";
import ColorfulCard from "./ColorfulCard";
import WhiteCard from "./WhiteCard";

export type Cards = "first" | "second" | "none";

const HowToOverCome = () => {
  const [cardsOpen, setCardsOpen] = useState<Cards>("none");

  return (
    <div className="my-10 md:my-20">
      <div className="mx-auto flex max-w-[600px] flex-col gap-5 px-6 md:mt-8 md:max-w-[1100px] md:flex-row md:items-center md:gap-10 md:px-10 lg:px-12">
        <div className="max-w-[280px] shrink-0 md:max-w-[362px]">
          <HMTypography variant="body1" className="">
            {l_holding("overcome.title_1")}
          </HMTypography>
          <HMTypography
            variant="body1"
            className="bg-clip-text"
            style={{
              backgroundImage: "linear-gradient(100deg, #FF06E6 20%,#0B06FF)",
            }}
          >
            {l_holding("overcome.title_2")}
            <HMTypography variant="span" className="text-transparent">
              {l_holding("overcome.title_3")}
            </HMTypography>
          </HMTypography>
        </div>
        <div className="w-full">
          <HMTypography variant="body2" className="md:leading-7">
            {l_holding("overcome.text")}
          </HMTypography>
        </div>
      </div>
      <BlackCard />
      <div
        className={cn(
          "mx-auto mt-12 max-w-[1300px] px-4 md:flex-row md:px-8 lg:px-12",
        )}
      >
        <div className="relative flex w-full flex-col gap-12 md:aspect-[1260/660]">
          <WhiteCard isOpen={cardsOpen} setIsOpen={setCardsOpen} />
          <ColorfulCard isOpen={cardsOpen} setIsOpen={setCardsOpen} />
        </div>
      </div>
    </div>
  );
};

export default HowToOverCome;
