import { ButtonBase, Modal } from "@mui/material";
import { ReactNode, useState } from "react";
import { animated, easings, useTransition } from "react-spring";
import HMTypography from "../../theme/HmTypography";

import type { SVGProps } from "react";
import { cn } from "../../../../../utils/cn";

export const BackArrowIcon = ({
  size,
  ...props
}: { size?: number } & SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <mask
      id="a"
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path fill="#D9D9D9" d="M0 0h24v24H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#1C1B1F"
        d="m8.654 13.049 2.192 2.192a.7.7 0 0 1 .221.538.785.785 0 0 1-.77.779.72.72 0 0 1-.547-.221l-3.52-3.52A.74.74 0 0 1 6 12.28q0-.307.23-.538l3.52-3.52a.72.72 0 0 1 .548-.22.785.785 0 0 1 .77.778.7.7 0 0 1-.222.539L8.654 11.51h8.577q.326 0 .548.221.22.222.221.548 0 .326-.221.548a.74.74 0 0 1-.548.222z"
      />
    </g>
  </svg>
);

const ModalBase = ({
  isOpen,
  setIsOpen,
  children,
  className,
}: {
  isOpen: boolean;
  setIsOpen: (v: boolean) => void;
  children: ReactNode;
  className?: string;
}) => {
  const [isClosing, setIsClosing] = useState(false);

  const transitions = useTransition(isOpen && !isClosing ? [1] : [], {
    from: { y: "100%" },
    enter: {
      y: "0%",
      config: { duration: 300, easing: easings.easeInOutQuad },
    },
    leave: {
      y: "120%",
      onResolve: () => {
        setIsOpen(false);
        setIsClosing(false);
      },
    },
  });

  return (
    <>
      <Modal open={isOpen} disablePortal onClose={() => setIsClosing(true)}>
        {transitions((style) => (
          <animated.div
            className={cn(
              "fixed bottom-0 left-0 box-border h-fit w-full rounded-t-[30px] bg-[#F4F4F4] px-5 py-6",
              className,
            )}
            style={style}
          >
            <ButtonBase
              onClick={() => {
                setIsClosing(true);
              }}
              className="flex items-center gap-1 rounded-xl p-2 pl-1 pr-3 hover:bg-black/[0.06] active:bg-black/[0.12]"
            >
              <BackArrowIcon size={24} />
              <HMTypography variant="body2" className="font-bold">
                Indietro
              </HMTypography>
            </ButtonBase>
            <div className="mt-6 px-2 pb-2">{children}</div>
          </animated.div>
        ))}
      </Modal>
    </>
  );
};

export default ModalBase;
