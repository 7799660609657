import { ButtonBase, useMediaQuery, useTheme } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { animated, easings, useSpring } from "react-spring";
import useResizeObserver from "use-resize-observer";
import { cn } from "../../../../../utils/cn";
import { CloseIcon } from "./CloseIcon";
import { Cards } from "./HowToOverCome";
import HMTypography from "../../theme/HmTypography";
import { l_holding } from "../../locales";
import ModalBase from "./ModalBase";

const ColorfulCard = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: Cards;
  setIsOpen: Dispatch<SetStateAction<Cards>>;
}) => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  const { ref, width } = useResizeObserver();

  const [shouldBeOnTop, setShouldBeOnTop] = useState(false);

  const containerAnimation = useSpring({
    scale: isOpen === "first" ? 0.7 : 1,
    right: isOpen === "first" ? "20%" : "0%",
    config: { duration: 400 },
  });

  const cardAnimation = useSpring({
    width: width,
    backgroundImage:
      isOpen === "second" && isOverMd
        ? "linear-gradient(45deg, rgba(203,60,203,0.8), rgba(227,168,227,0) 40%), linear-gradient(280deg, rgba(184,165,85,0.6), rgba(153,48,230,0.8) 80%), linear-gradient(80deg, rgba(203,60,203,0.3), rgba(255,255,255,0.2) 100%)"
        : "linear-gradient(100deg, rgba(203,60,203,0.8), rgba(203,60,203,0) 50%), linear-gradient(180deg, rgba(248,187,85,0.8), rgba(160,38,230,0.8) 100%), linear-gradient(270deg, rgba(255,255,255,0), rgba(255,255,255,0) 100%)",
    config: { duration: 500, easing: easings.easeInOutSine },
    onRest: () => {
      if (isOpen !== "second") {
        setShouldBeOnTop(false);
      }
    },
  });

  return (
    <>
      <animated.div
        style={isOverMd ? containerAnimation : {}}
        className={cn(
          "right-0 top-0 h-full w-[calc(50%-10px)] md:absolute",
          shouldBeOnTop && "z-[2]",
          (isOpen === "second" || !isOverMd) && "w-[100%]",
        )}
        ref={ref}
      >
        <animated.div
          className={cn(
            "relative right-0 top-0 h-full w-full rounded-[40px] bg-white shadow-[rgba(199,199,206,0.2)_0px_0px_30px_0px] md:absolute md:aspect-auto",
          )}
          style={{
            ...(isOverMd
              ? cardAnimation
              : { backgroundImage: cardAnimation.backgroundImage }),
          }}
        >
          <HMTypography
            variant="title"
            className={cn(
              "box-border px-10 py-10 text-[48px] leading-[52px] text-white transition-opacity duration-75 md:text-[60px] md:leading-[64px] lg:px-12 lg:pt-12 lg:text-[92px] lg:leading-[96px]",
              isOpen === "second" && isOverMd && "opacity-[0.74]",
            )}
          >
            {l_holding("overcome.colorfulCard_title1")}
            <br />
            {l_holding("overcome.colorfulCard_title2")}
            <br />
            {l_holding("overcome.colorfulCard_title3")}
            <br />
            {l_holding("overcome.colorfulCard_title4")}
            <br />
            {l_holding("overcome.colorfulCard_title5")}
          </HMTypography>
          {isOverMd && (
            <animated.div
              className={cn(
                "right-10 top-10 box-border flex h-[calc(100%-40px-40px)] max-h-[320px] w-[calc(60%-40px-40px)] flex-col items-center justify-between transition-all md:absolute lg:right-12 lg:top-12 lg:h-[calc(90%-48px-48px)] lg:max-h-[380px] lg:w-[calc(50%-48px-48px)]",
                isOpen === "second"
                  ? "opacity-1 delay-[500ms] duration-75"
                  : "opacity-0 delay-0 duration-75",
              )}
            >
              <HMTypography
                variant="body2"
                className="text-[18px] text-white lg:text-[20px]"
              >
                {l_holding("overcome.colorfulCard_text1")}
              </HMTypography>
              <HMTypography
                variant="body2"
                className="text-[18px] text-white lg:text-[20px]"
              >
                {l_holding("overcome.colorfulCard_text2")}
              </HMTypography>
            </animated.div>
          )}

          <div className="absolute bottom-10 right-10 lg:bottom-12 lg:right-12">
            <ButtonBase
              onClick={() => {
                setShouldBeOnTop(true);
                setIsOpen((p) => (p === "second" ? "none" : "second"));
              }}
              className={cn(
                "flex items-center justify-center rounded-full bg-[#D9D9D9] p-1 duration-500 hover:bg-[#cfcfcf]",
                isOpen === "second" && isOverMd ? "rotate-0" : "rotate-45",
              )}
            >
              <CloseIcon color="white" size={30} />
            </ButtonBase>
          </div>
        </animated.div>
      </animated.div>
      <ModalBase
        isOpen={isOpen === "second" && !isOverMd}
        setIsOpen={() => setIsOpen("none")}
      >
        <div>
          <HMTypography variant="title" className="mb-6">
            {l_holding("overcome.colorfulCard_title1")}{" "}
            {l_holding("overcome.colorfulCard_title2")}{" "}
            {l_holding("overcome.colorfulCard_title3")}{" "}
            {l_holding("overcome.colorfulCard_title4")}{" "}
            {l_holding("overcome.colorfulCard_title5")}
          </HMTypography>
          <div className="max-h-[55vh] overflow-y-auto">
            <HMTypography variant="body2" className="mb-6">
              {l_holding("overcome.colorfulCard_text1")}
            </HMTypography>
            <HMTypography variant="body2" className="mb-6">
              {l_holding("overcome.colorfulCard_text2")}
            </HMTypography>
          </div>
        </div>
      </ModalBase>
    </>
  );
};

export default ColorfulCard;
