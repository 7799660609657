import { useMediaQuery, useTheme } from "@mui/material";
import MabiParallax from "../../../../../components/MabiloftSmoothScrollInterpolator";
// @ts-ignore
import imgCenter from "./images/central.png";
// @ts-ignore
import imgLeft from "./images/left.png";
// @ts-ignore
import imgRight from "./images/right.png";
// @ts-ignore
import imgScrolling from "./images/scrolling.png";
import { selector } from "gsap";

const ImagesSectionBig = () => {
  const tweens = [
    {
      selector: "#hm_container",
      from: {
        scale: 0.9,
      },
      to: {
        scale: 1,
      },
      delay: 0.2,
    },
    {
      selector: "#hm_left",
      from: {
        x: "-30%",
        scale: 0.5,
        opacity: 0,
      },
      to: {
        x: "-72%",
        scale: 1,
        opacity: 0.6,
      },
      delay: 0.3,
    },
    {
      selector: "#hm_right",
      from: {
        x: "40%",
        scale: 0.5,
        opacity: 0,
      },
      to: {
        x: "90%",
        scale: 1,
        opacity: 0.6,
      },
      delay: 0.3,
    },
  ];

  return (
    <div className="w-full overflow-x-hidden py-10">
      <MabiParallax id="holdingModaImagesSectionBig" tweens={tweens}>
        <div
          id="hm_container"
          className="relative z-[3] m-0 mx-auto box-border aspect-[1344/840] w-[60%] max-w-[1200px] rounded-[30px] p-0 shadow-[rgba(100,100,111,0.2)_0px_7px_29px_0px]"
        >
          <img
            src={imgCenter}
            className="absolute left-0 top-0 z-[3] m-0 box-border h-full min-h-full w-full rounded-[30px] p-0"
          />
          <div
            id="hm_left"
            className="absolute left-0 top-1/2 z-[1] aspect-[1088/680] w-[90%] max-w-[800px] -translate-y-1/2 overflow-hidden rounded-[30px]"
          >
            <img src={imgLeft} className="w-full" />
          </div>
          <div
            id="hm_right"
            className="absolute right-0 top-1/2 z-[1] aspect-[1081/667] w-[90%] max-w-[800px] -translate-y-1/2 overflow-hidden rounded-[30px]"
          >
            <img src={imgRight} className="w-full" />
          </div>
        </div>
      </MabiParallax>
    </div>
  );
};

const ImagesSectionSmall = () => {
  const theme = useTheme();
  const isOverSm = useMediaQuery(theme.breakpoints.up("sm"));

  const tweens = [
    {
      selector: "#scrollingImage",
      from: {
        backgroundPosition: "50% 0%",
      },
      to: {
        backgroundPosition: isOverSm ? "50% 20%" : "50% 45%",
        delay: 0.5,
      },
    },
    ...(isOverSm
      ? [
          {
            selector: "#scrollingImage_double",
            from: {
              backgroundPosition: "50% 60%",
            },
            to: {
              backgroundPosition: "50% 45%",
              delay: 0.5,
            },
          },
        ]
      : []),
  ];
  return (
    <MabiParallax
      id="holdingModaImagesSectionSmall"
      duration={1}
      tweens={tweens}
    >
      <div className="box-border flex w-full items-center justify-between gap-8 px-6">
        <div
          className="mx-auto box-border aspect-[1/2] w-full max-w-[300px] rounded-[30px]"
          id="scrollingImage"
          style={{
            backgroundImage: `url(${imgScrolling})`,
            backgroundSize: "cover",
            backgroundPosition: "50% 10%",
          }}
        />
        {isOverSm && (
          <div
            className="mx-auto box-border aspect-[1/2] w-full max-w-[300px] rounded-[30px]"
            id="scrollingImage_double"
            style={{
              backgroundImage: `url(${imgScrolling})`,
              backgroundSize: "cover",
              backgroundPosition: "50% 10%",
            }}
          />
        )}
      </div>
    </MabiParallax>
  );
};

const ImagesSection = () => {
  const theme = useTheme();
  const isOvermd = useMediaQuery(theme.breakpoints.up("md"));
  return isOvermd ? <ImagesSectionBig /> : <ImagesSectionSmall />;
};

export default ImagesSection;
