import { DeepKeyof, language } from "../../../traductions";

const traduction = {
  it: {
    seo: {
      title: "Holding moda",
      description:
        "Con Holding Moda abbiamo trasformato l'esperienza di acquisto introducendo un magazzino digitale che consente agli utenti di esplorare in modo coinvolgente  tutte le possibili lavorazioni e tessuti offerti dalla Holding.",
    },
    header: {},
    identifyingProblem: {
      title: "Identificare il problema.",
      text1:
        "Holding Moda, il fiore all'occhiello della produzione di alta moda italiana che racchiude più di 11 aziende specializzate, si è trovata di fronte a una sfida intrigante:",
      text2:
        "la dispersione globale dei magazzini rendeva difficile ai clienti esplorare l'intera collezione di materiali e lavorazioni a catalogo.",
      text3_1:
        "Infatti, dopo aver analizzato attentamente i comportamenti del personale e dei clienti, ",
      text3_2:
        "è emerso che trovare il prodotto desiderato nel vasto assortimento era un processo complesso e macchinoso",
      text3_3:
        " a causa dell’alto volume di opzioni e la distribuzione geografica dei magazzini.",
    },
    overcome: {
      title_1: "Cosa abbiamo fatto per superare ",
      title_2: "questa ",
      title_3: "sfida",
      text: "Per superare questa sfida abbiamo adottato un approccio strategico concentrandoci su come rendere più efficiente la consultazione del magazzino: abbiamo osservato e intervistato le persone coinvolte per mappare le necessità al meglio, con l’obiettivo di semplificare il processo, garantendo a tutti un accesso agevole alle opzioni offerte da Holding Moda.",
      blackCard_text1:
        "Abbiamo trasformato radicalmente l'esperienza di acquisto presso Holding Moda introducendo una soluzione tecnologicamente avanzata: il magazzino digitale consente agli utenti di esplorare in modo coinvolgente ed interattivo tutte le possibili lavorazioni e tessuti offerti dalla Holding.",
      blackCard_text2:
        "Questo risultato è reso possibile dalla fluida connessione tra i supporti tecnologici utilizzati, tablet e maxi schermo, che garantisce una visualizzazione in grande formato ad altissima risoluzione dei prodotti.",
      blackCard_title: "Un intero magazzino in uno schermo",
      whiteCard_title: "Esperienza cucita su misura",
      whiteCard_text:
        "Sfruttando la nostra competenza nel design di esperienze e nello sviluppo ad alto tasso tecnologico, abbiamo progettato una piattaforma che risponda alle specifiche esigenze del cliente.",
      colorfulCard_title1: "Moda",
      colorfulCard_title2: " hi-tech,",
      colorfulCard_title3: "il lato",
      colorfulCard_title4: "tech della",
      colorfulCard_title5: "moda",
      colorfulCard_text1:
        "Grazie a un processo di ricerca approfondito, abbiamo identificato e sviluppato caratteristiche chiave fondamentali per il successo del progetto, garantendo un'esperienza di utilizzo impeccabile.",
      colorfulCard_text2:
        "Ci siamo concentrati sull'ottimizzazione dell'interfaccia su un ampio schermo (TV da 85 pollici) e sull'implementazione di una connessione rapida attraverso QR code da tablet. Queste scelte tecniche hanno contribuito in modo significativo a migliorare l'accessibilità e l'interattività del nostro showroom digitale, offrendo agli utenti un'esperienza coinvolgente e senza intoppi durante l'esplorazione della collezione.",
    },
    technologyBehind: {
      title1: "La tecnologia dietro il progetto",
      title2: "Mappa dell’innovazione.",
      text1_1: "La nostra tecnologia è stata ",
      text1_2: "integrata con successo nel magazzino di HM",
      text1_3: ", rendendo il magazzino virtuale ",
      text1_4: "accessibile in tutti i centri Holding Moda nel mondo",
      text1_5:
        ", nelle maggiori capitali della moda tra cui Parigi, con il valore aggiunto del supporto offline.",
      text2_1: "Questa nuova esperienza di vendita ha ",
      text2_2: "notevolmente ridotto i tempi decisionali dei clienti",
      text2_3: " durante la fase di acquisto.",
    },
  },
  en: {
    seo: {
      title: "Holding moda",
      description:
        "With Holding Moda we have transformed the shopping experience by introducing a digital warehouse that allows users to explore in an engaging way all the possible processes and fabrics offered by the Holding.",
    },
    header: {},
    identifyingProblem: {
      title: "Identify the problem.",
      text1:
        "Holding Moda, the flagship of Italian high fashion production which includes more than 11 specialized companies, found itself faced with an intriguing challenge:",

      text2:
        "the global dispersion of warehouses made it difficult for customers to explore the entire collection of materials and manufacturing in the catalogue.",
      text3_1:
        "In fact, after carefully analyzing the behavior of staff and customers, ",
      text3_2:
        "it turned out that finding the desired product in the vast assortment was a complex and cumbersome process",
      text3_3:
        " due to the high volume of options and the geographic distribution of warehouses.",
    },
    overcome: {
      title_1: "What did we do to overcome ",
      title_2: "this ",
      title_3: "challenge",
      text: "To overcome this challenge, we adopted a strategic approach focusing on how to make the warehouse consultation more efficient: we observed and interviewed the people involved to map the needs in the best possible way, with the aim of simplifying the process, ensuring everyone easy access to the options offered by Holding Moda.",

      blackCard_text1:
        "We have radically transformed the shopping experience at Holding Moda by introducing a technologically advanced solution: the digital warehouse allows users to explore in an engaging and interactive way all the possible manufacturing and fabrics offered by the Holding.",
      blackCard_text2:
        "This result is made possible by the fluid connection between the technological supports used, tablets and maxi screens, which guarantees a very high resolution large format display of the products.",
      blackCard_title: "A whole warehouse on the screen",
      whiteCard_title: "Tailor-made experience",
      whiteCard_text:
        "Leveraging our expertise in design of experience and high-tech development, we have designed a platform that responds to the specific needs of the customer.",
      colorfulCard_title1: "Hi-tech",
      colorfulCard_title2: "fashion,",
      colorfulCard_title3: "the tech",
      colorfulCard_title4: "side of",
      colorfulCard_title5: "fashion,",
      colorfulCard_text1:
        "Thanks to an in-depth research process, we identified and developed key features critical to the success of the project, ensuring a flawless user experience.",
      colorfulCard_text2:
        "We focused on optimizing the interface on a large screen (85-inch TV) and implementing a quick connection via QR code from a tablet. These technical choices have significantly contributed to improving the accessibility and interactivity of our digital showroom, offering users an engaging and seamless experience when exploring the collection.",
    },
    technologyBehind: {
      title1: "The technology behind the project",
      title2: "Innovation map.",
      text1_1: "Our technology was ",
      text1_2: "successfully integrated in HM warehouse",
      text1_3: ", making the virtual warehouse ",
      text1_4: "accessible in every Holding Moda center around the world",
      text1_5:
        ", in the major fashion capitals including Paris, with the added value of offline support.",
      text2_1: "This new sales experience has ",
      text2_2: "significantly reduced customer decision-making times",
      text2_3: " during the purchase phase.",
    },
  },
};

export const l_holding = (
  trad: DeepKeyof<(typeof traduction)[keyof typeof traduction]>,
) => {
  const tokens = trad.split(".");
  let resource = traduction && (traduction as any)[language.code];
  if (!resource) {
    return tokens[tokens.length - 1];
  }

  for (let c = 0; c < tokens.length; c++) {
    resource = resource[tokens[c]];
    if (!resource) {
      // resource not found
      return trad;
    }
  }

  return resource;
};
