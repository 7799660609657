export function CloseIcon({
  color,
  size,
  ...props
}: {
  color?: string;
  size?: number & React.SVGProps<SVGSVGElement>;
}) {
  return (
    <svg
      width={size}
      height={size}
      preserveAspectRatio="none"
      viewBox={`0 0 24 24`}
      fill="blue"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.357 7.815a.829.829 0 00-1.172-1.172L12 10.828 7.815 6.643a.829.829 0 10-1.172 1.172L10.828 12l-4.185 4.185a.829.829 0 001.172 1.173L12 13.173l4.185 4.185a.829.829 0 001.172-1.173L13.172 12l4.185-4.185z"
        fill={color ?? "#FFF"}
      />
    </svg>
  );
}
