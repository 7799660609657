// @ts-ignore
import centralPic from "./images/black_girl_center.png";
// @ts-ignore
import girlPic from "./images/black_girl_side.png";
// @ts-ignore
import boyPic from "./images/black_boy_side.png";
import { cn } from "../../../../../utils/cn";
import { useEffect, useState } from "react";
import { animated, easings, useSpring } from "react-spring";
import { useMediaQuery, useTheme } from "@mui/material";

const Header = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));
  const [isHovered, setIsHovered] = useState(false);

  const coloredCardAnimation = useSpring({
    width: isOverMd ? (isHovered ? "50%" : "40%") : isHovered ? "80%" : "85%",
    borderRadius: isHovered ? "60px" : "40px",
    backgroundColor: isHovered ? "rgba(255,255,255,0)" : "rgba(255,255,255,1)",
    backgroundImage: isHovered
      ? "linear-gradient(rgba(203,60,203,0.8), rgba(255, 255, 255, 0) 90%)"
      : "linear-gradient(rgba(248,187,85,0.8), rgba(248,187,85,0.2) 80%)",
    config: { duration: 400, easing: easings.easeInOutSine },
  });

  const imgContainerAnimation = useSpring({
    width: isOverMd ? (isHovered ? "60%" : "40%") : isHovered ? "95%" : "85%",
    borderRadius: isHovered ? "0px" : "40px",
    config: { duration: 400, easing: easings.easeInOutSine },
  });

  const imgAnimation = useSpring({
    width: isOverMd
      ? isHovered
        ? "100%"
        : "140%"
      : isHovered
        ? "100%"
        : "140%",
    config: { duration: 400, easing: easings.easeInOutSine },
  });

  const girlCard = useSpring({
    width: isHovered ? "10%" : "30%",
    translateX: isHovered ? "0%" : "-20%",
    right: isHovered ? "20%" : "0%",
    opacity: isHovered ? 0 : 1,
    config: { duration: 350, easing: easings.easeInOutSine },
  });
  const boyCard = useSpring({
    width: isHovered ? "10%" : "30%",
    translateX: isHovered ? "0%" : "20%",
    left: isHovered ? "20%" : "0%",
    opacity: isHovered ? 0 : 1,
    config: { duration: 350, easing: easings.easeInOutSine },
  });

  useEffect(() => {
    const timeoutStart = setTimeout(() => {
      setIsHovered(true);
    }, 500);

    const timeoutStop = setTimeout(() => {
      setIsHovered(false);
    }, 2300);

    return () => {
      clearTimeout(timeoutStart);
      clearTimeout(timeoutStop);
    };
  }, []);

  return (
    <div className="relative mx-auto mb-10 mt-10 w-full max-w-[1300px] md:mt-20">
      {/* // This is the pink background */}
      <div className="absolute top-20 h-[90%] w-full rounded-full bg-[rgba(203,60,203,0.1)] blur-3xl" />
      <div className="relative top-20 mb-[20%] aspect-[466/530] w-[80%] md:w-[40%]" />
      <animated.div
        className={cn(
          "absolute left-1/2 top-0 z-[2] mx-auto aspect-[466/530] w-[40%] -translate-x-1/2 rounded-[40px] outline-1 outline-transparent",
        )}
        style={coloredCardAnimation}
      />
      <animated.div
        className={cn(
          "absolute left-1/2 top-0 z-[3] mx-auto box-border aspect-[466/530] w-[40%] -translate-x-1/2 cursor-pointer overflow-clip rounded-[40px] pt-10 outline outline-1 outline-transparent",
        )}
        style={imgContainerAnimation}
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
      >
        <animated.div
          className={cn(
            "absolute left-1/2 top-10 w-[140%] -translate-x-1/2 bg-[#ffffff03]",
          )}
          style={{
            boxShadow: "0 0 1px rgba(0, 0, 0, 0.05)",
            WebkitBackfaceVisibility: "hidden",
            ...imgAnimation,
          }}
        >
          <img src={centralPic} className="w-full" />
        </animated.div>
      </animated.div>
      {isOverMd && (
        <>
          <animated.div
            className="absolute left-0 top-[10%] aspect-[466/535] w-[30%] -translate-y-1/2 translate-x-[20%] overflow-hidden rounded-[40px] bg-white"
            style={boyCard}
          >
            <div className="absolute left-1/2 top-10 w-[60%] -translate-x-1/2">
              <img src={boyPic} className="w-full" />
            </div>
          </animated.div>
          <animated.div
            className="absolute right-0 top-[10%] aspect-[466/535] w-[30%] -translate-y-1/2 translate-x-[-20%] overflow-hidden rounded-[40px] bg-white"
            style={girlCard}
          >
            <div className="absolute left-1/2 top-10 w-[60%] -translate-x-1/2">
              <img src={girlPic} className="w-full" />
            </div>
          </animated.div>
        </>
      )}
    </div>
  );
};

export default Header;
