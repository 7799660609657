import { useMediaQuery, useTheme } from "@mui/material";
import { l_holding } from "../../locales";
import HMTypography from "../../theme/HmTypography";
import ImagesSection from "./ImagesSection";

const TechnologyBehind = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <div className="my-10 md:my-20">
      {isOverMd && (
        <HMTypography variant="body1" className="mb-10 px-4 text-center">
          {l_holding("technologyBehind.title1")}
        </HMTypography>
      )}
      <ImagesSection />
      <div className="mx-auto mt-10 max-w-[600px] px-6 md:mt-20 md:max-w-[1100px] md:px-10 lg:px-12">
        <HMTypography
          variant={"body1"}
          className="mb-6 max-w-[180px] text-black/[0.2] md:mb-12 md:max-w-[250px]"
        >
          {l_holding("technologyBehind.title2")}
        </HMTypography>
        <HMTypography
          variant={"body1"}
          className="mb-8 bg-clip-text md:mb-12"
          style={{
            backgroundImage:
              "linear-gradient(100deg, #FF0000,#FF06E6,#0B06FF,#FFAA06)",
          }}
        >
          {l_holding("technologyBehind.text1_1")}
          <HMTypography variant="span" className="text-transparent">
            {l_holding("technologyBehind.text1_2")}
          </HMTypography>
          {l_holding("technologyBehind.text1_3")}
          <HMTypography variant="span" className="text-transparent">
            {l_holding("technologyBehind.text1_4")}
          </HMTypography>
          {l_holding("technologyBehind.text1_5")}
        </HMTypography>
        <HMTypography
          variant={"body1"}
          className="mb-8 bg-clip-text md:mb-12"
          style={{
            backgroundImage:
              "linear-gradient(100deg, #FF0000,#FF06E6,#0B06FF,#FFAA06)",
          }}
        >
          {l_holding("technologyBehind.text2_1")}
          <HMTypography variant="span" className="text-transparent">
            {l_holding("technologyBehind.text2_2")}
          </HMTypography>
          {l_holding("technologyBehind.text2_3")}
        </HMTypography>
      </div>
    </div>
  );
};

export default TechnologyBehind;
