import { ButtonBase, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { l_holding } from "../../locales";
import HMTypography from "../../theme/HmTypography";
import { CloseIcon } from "./CloseIcon";
import { cn } from "../../../../../utils/cn";
//@ts-ignore
import pcImg from "./images/pc_screenshot.png";
import ModalBase from "./ModalBase";

const BlackCard = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen && !isOverMd) {
      //@ts-ignore
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen, isOverMd]);

  return (
    <div className="px-4 md:px-8 lg:px-12">
      <div className="relative mx-auto mt-20 box-border flex w-full max-w-[1300px] flex-col-reverse rounded-[40px] bg-[#121212] px-10 pt-10 shadow-[rgba(199,199,206,0.2)_0px_0px_30px_0px] md:mt-12 md:aspect-[1260/500] md:flex-row md:px-0 md:pt-0 lg:aspect-[1260/420] xl:aspect-[1260/360]">
        <div className="relative box-border h-full w-full shrink-0 overflow-hidden md:w-[60%]">
          <div
            className={cn(
              "left-0 box-border w-full transition-all duration-700 md:absolute md:px-10 lg:px-12",
              isOverMd && (isOpen ? "top-0" : "top-full -translate-y-[100%]"),
            )}
          >
            {isOverMd && (
              <div className="flex flex-col md:my-10 lg:my-12">
                <HMTypography
                  variant="body2"
                  className={cn(
                    "text-white md:text-[16px] lg:text-[20px] xl:text-[20px]",
                  )}
                >
                  {l_holding("overcome.blackCard_text1")}
                </HMTypography>
                <HMTypography
                  variant="body2"
                  className="mt-2 text-white md:text-[16px] lg:text-[20px] xl:text-[20px]"
                >
                  {l_holding("overcome.blackCard_text2")}
                </HMTypography>
              </div>
            )}
            <img
              src={pcImg}
              className="relative left-1/2 w-full -translate-x-1/2 translate-y-[15%] rounded-2xl opacity-70 shadow-[rgba(199,199,206,0.2)_0px_-7px_30px_0px] md:mt-[50%] md:opacity-100 lg:w-[90%] xl:w-[80%]"
            />
          </div>
        </div>

        <div
          className={cn(
            "w-full flex-grow-0 md:relative md:my-8 md:mr-10 lg:my-12 lg:mr-12",
          )}
        >
          <HMTypography
            variant="title"
            className={cn(
              "max-w-[350px] transition-colors duration-500",
              isOpen ? "text-white/[0.48]" : "text-white",
            )}
          >
            {l_holding("overcome.blackCard_title")}
          </HMTypography>

          <div className="absolute bottom-10 right-10 md:bottom-0 md:right-0">
            <ButtonBase
              onClick={() => {
                setIsOpen((p) => !p);
              }}
              className={cn(
                "flex items-center justify-center rounded-full bg-white p-1 duration-500 hover:bg-white/[0.88]",
                isOpen && isOverMd ? "rotate-0" : "rotate-45",
              )}
            >
              <CloseIcon color="black" size={30} />
            </ButtonBase>
          </div>
        </div>
      </div>
      <ModalBase isOpen={isOpen && !isOverMd} setIsOpen={setIsOpen}>
        <div>
          <HMTypography variant="title" className="mb-6">
            {l_holding("overcome.blackCard_title")}
          </HMTypography>
          <div className="max-h-[50vh] overflow-y-auto">
            <HMTypography variant="body2" className="mb-6">
              {l_holding("overcome.blackCard_text1")}
            </HMTypography>
            <HMTypography variant="body2" className="mb-6">
              {l_holding("overcome.blackCard_text2")}
            </HMTypography>
          </div>
        </div>
      </ModalBase>
    </div>
  );
};

export default BlackCard;
