import React, { useEffect } from "react";
import Header from "./components/Header/Header";
import IdentifyProblem from "./components/IdentifyProblem/IdentifyProblem";
import PrevNextCaseStudy from "../../../components/shared/PrevNextCaseStudy/PrevNextCaseStudy";
import HowToOverCome from "./components/HowToOvercome/HowToOverCome";
import TechnologyBehind from "./components/TechnologyBehind/TechnologyBehind";
import Footer from "../../../components/shared/Footer/Footer";

const HoldingModa = () => {
  return (
    <div className="overflow-y-hidden">
      <Header />
      <IdentifyProblem />
      <HowToOverCome />
      <TechnologyBehind />
      <PrevNextCaseStudy />
      <Footer />
    </div>
  );
};

export default HoldingModa;
